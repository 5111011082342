<template>
  <div class=" p-20">
    <!--begin::Head-->
    <div
        class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-white">
        Already have an account?
      </span>
      <router-link
          class="font-weight-bold font-size-3 ml-2"
          :to="{ name: 'login' }"
      >
        Sign in!
      </router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="text-center mb-5 mb-lg-5">
          <div class="block" style="background: #15232c; padding-top: 15px ">
            <img
                src="/media/logos/score_logo.png"
                alt="Score"
                width="180px"
            />
          </div>
          <h3 class="font-size-h2" style="background: #15232c;color: #fff;padding: 15px;">Admin login</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-1"
            label
            label-for="example-input-1"
          >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="example-input-1"
              name="example-input-1"
              placeholder="Enter your email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback"
              >Please enter a valid email address.</b-form-invalid-feedback
            >
          </b-form-group>

          <!--begin::Divider-->
          <div class="kt-login__divider">
            <div class="kt-divider"></div>
          </div>
          <!--end::Divider-->

          <vue-recaptcha
            ref="invisibleRecaptcha"
            :sitekey="sitekey"
            :loadRecaptchaScript="true"
            size="invisible"
            @error="onError"
          ></vue-recaptcha>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <router-link
               class="font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
              :to="{ name: 'login' }"
              >Back</router-link
            >

            <b-button
              :disabled="!recaptchaCheked"
              type="submit"
              id="kt_submit"
              class="btn btn-admin-login  btn-primary text-white font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
              >Send password reset email</b-button
            >
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
    <vue-snotify></vue-snotify>
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT, VERIFY_AUTH } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
/* import VueSocialauth from "vue-social-auth"; */
import { API_URL} from "@/core/config";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      siteUrl: API_URL,
      recaptchaCheked: true,
      // Remove this dummy login info
      form: {
        email: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    redirectIfLoggedIn() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({ name: "dashboard" });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onError: function () {
      this.recaptchaCheked = false;
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        ApiService.post("admin/reset/password", {
          email: email,
        })
          .then((response) => {
            this.resetForm();
            this.$snotify.success(response.data.msg, {
              position: "rightBottom",
              timeout: 5000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            });

          })
          .catch(({ response }) => {
            this.$snotify.error(response.data.msg, {
              position: "rightTop",
              timeout: 5000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            });
          });

       submitButton.classList.remove(
          "spinner", "spinner-light", "spinner-right"
        );
      }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
  mounted: function () {
    this.redirectIfLoggedIn();
  },
};
</script>
